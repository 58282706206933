// OBJECT.CONTROL

@mixin control__heading {
	display: block;
	margin-bottom: $bsu;
	@include bp("large") {
		margin-bottom: $lsu;
	}

	@include font-heading-20;
	@include bp("medium") {
		@include font-heading-24;
	}
	@include bp("large") {
		@include font-heading-28;
	}
	color: var(--secondary-a);
}


.o-control {
	@include o-control;
}

.o-fullwidth-control {
	@include o-control;

	&:first-child {
		margin-top: 0;
	}

	+ .o-fullwidth-control {
		margin-top: calc(var(--control-gap) * -1);
	}
}

.o-control__heading {
	@include control__heading;
}

.o-control__figcaption {
	@include control__figcaption;
}
