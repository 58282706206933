// CONTROL.PEOPLE--MAIN

@use "sass:math";

.c-people--main,
.c-people-listing {

	.c-people__heading {
		@include control__heading;
	}

	.c-people__person-shape {
		&::before {
			background: linear-gradient(to top, rgba($carcoal, 0.95) 0%, rgba($carcoal, 0) 30%);
		}
	}

	.c-people__person-name {
		@include font-heading-20;
	}

	.c-people__person-title {

		@include font-base-18;
	}

	.c-people__person-icon {
		flex-shrink: 0;
		margin-left: $bsu;
		background-color: transparent;

		@include flex-extend;
		align-items: center;
		justify-content: center;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		border: 1px solid currentColor;
		font-size: 16px;

		@include link-transition;
	}
}
