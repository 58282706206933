﻿// CONTROL.FORM

@use "sass:math";

.c-form__heading {
	@include control__heading;
}

.c-form__form {
	background: $green--grey;
	&.t-before-bg {
		&::before {
			background-color: var(--secondary-a);
		}
	}
}

.c-form__field {
	margin-bottom: $bsu;

	&:last-child {
		margin-bottom: 0;
	}
}
.c-form__field-label {
	@include font-base-16;
}
.c-form__required-indicator {
	color: $red;
}


.umbraco-forms-caption {
	@include font-heading-18;
	@include bp("medium") {
		@include font-heading-24;
	}
	margin-bottom: 1.5em;
}

.umbraco-forms-fieldset {
	@include ui-border('bottom');
	padding-bottom: $lsu;
	margin-bottom: $lsu;

	legend {
		padding-bottom: $bsu;
	}
}

.umbraco-forms-label-wrapper {
	order: 2;
}

.umbraco-forms-label, .c-form__label {
	@include font-base-16;
	display: block;
}

.umbraco-forms-tooltip {
	@include font-base-14;
	color: $grey--dark;
}

.umbraco-forms-form .btn {
	@include button;
	color: var(--primary-b);
	background-color: var(--primary-a);

	&:hover,
	&:focus {
		color: var(--secondary-b);
		background-color: var(--secondary-a);
	}

	@include bp("large") {
		@include button-size("large");
	}

	&.cancel {
		background-color: $error;

		&:hover,
		&:focus {
			background-color: darken($error, 10%);
		}
	}
}

.field-validation-error, .c-form__error-message, .validation-summary-errors {
	@include font-base-14;
	color: $error;
}

.umbraco-forms-field-wrapper {
	margin-top: $ssu;

	.date & {
		position: relative;

		@include bp("small") {
			max-width: 250px;
		}

		&:after {
			position: absolute;
			display: block;
			right: $ssu;
			top: 15px;
			font-family: #{$icomoon-font-family};
			content: $iconf-calendar;
			pointer-events: none;
		}
	}

	.checkbox & {
		margin-top: 0;
	}

	.singlecheckbox & {
		order: -1;
		margin-right: $tsu;
	}

	.titleanddescription & {
		margin-top: 0;
	}

	.checkboxlist, .radiobuttonlist {
		label {
			display: inline-block;
			margin-bottom: calc($ssu / 2);
			margin-left: 3px;
		}

		label:last-of-type {
			margin-bottom: 0;
		}
	}
}

.umbraco-forms-navigation {
	text-align: right;
}


.titleanddescription {

	h2 {
		@include font-heading-32;
		margin-bottom: 0.5em;
	}

	p {
		@include font-base-20;

		&:last-child {
			margin-bottom: 0;
		}
	}
}


// Fake inputs
.c-fake-checkbox {
	&:checked ~ .c-fake-checkbox__display {
		&::before {
			border-color: $brand;
		}
	}

	&:focus ~ .c-fake-checkbox__display {
		@include focus-outline;
	}
}

// Fake Radio
.c-fake-radio {
	&:checked ~ .c-fake-radio__display {
		&::before {
			background-color: $brand;
		}
	}
	&:focus ~ .c-fake-radio__display {
		@include focus-outline;
	}
}