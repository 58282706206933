﻿// CONTROL.FEATURED-CAROUSEL

.c-feature-carousel__heading {
	@include font-base-28sb;

	@include bp("medium") {
		@include font-base-36sb;
	}

	@include bp("large") {
		@include font-base-52sb;
	}

	@include bp("extra-large") {
		@include font-base-60sb;
	}
}