// COMPONENT.HEADER

@use "sass:math";

$header-bp: "large";
$header-bp--large: "extra-large";

$header-height: 180px;

:root {
	--header-height: 65px;
}

@include bp($header-bp) {
	:root {
		--header-height: 180px;
	}
}

.c-header {
	position: fixed;
	z-index: $z-header;
	height: $header-height;
	height: var(--header-height);
	//background-color: transparent;
	background-color: var(--secondary-a);
	width: 100%;
	box-shadow: 0 8px 6px -6px rgba($black, 0);
	transition: all 0.25s ease-in-out;
	top: 0;



	&.is-up {
		transform: translateY(calc(-100% - 6px));

		&.is-open {
			transform: none;
		}
	}

	&.is-open,
	&.is-expanded {
		background-color: var(--secondary-a);
	}

	&.no-hero {
		background-color: var(--secondary-a);
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);

		&.is-open,
		&.is-expanded,
		&.is-sticky {
			background-color: var(--secondary-a);
		}
	}
}

.c-header__spacer {
	display: block;
}

// Global Nav

.c-header__global-nav-link {
	@include font-base-12;

	&:hover,
	&:focus,
	.c-header__global-nav-item[aria-current="true"] & {
		background: var(--secondary-a);
		color: var(--secondary-b);
	}
}

.c-header__logo {
	$header-logo-height: 90px;
	//$header-logo-padding: ($header-height - $header-logo-height / 5);
	$header-logo-padding: 10px;

	--header-logo-height: 90px;

	@include bp-below($header-bp--large) {
		--header-logo-height: 90px;
	}

	@include bp-below($header-bp) {
		--header-logo-height: 60px;
	}

	--header-logo-padding: calc((var(--header-height) - var(--header-logo-height)) / 4);
	display: inline-block;
	padding: calc($header-logo-padding / 2) 0;
	//padding: var(--header-logo-padding) $su var(--header-logo-padding) 0;
	text-align: center;
	height: $header-logo-height;
	height: var(--header-logo-height);
	order: 0;
	z-index: 1;

	@include bp("large") {
		padding: 0 $bsu 0 0;
		order: 0;
		margin-bottom: -90px;
	}

	@include bp("extra-large") {
		order: 1;
		margin-bottom: 0;
		margin-top: -#{$bsu};
	}

	img {
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}

	&:focus {
		@include focus-outline;
	}
}

.c-header__tools {
	@include flex-extend;
	flex: 1 0 auto;
	align-items: center;
	justify-content: flex-end;
	order: 3;

	@include bp($header-bp) {
		margin-top: $tsu;
		flex: 0 1 auto;
	}

	@include bp("extra-lareg") {
		margin-top: 0;
	}
}

// Secondary Nav

.c-header__secondary-nav-link {
	@include font-base-12;

	&.t-link--primary-hover:hover,
	&.t-link--primary-hover:focus {
		color: $green--pale;
	}
}

// Language

// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $white;
$mobile-button--hover-color: darken($white, 10);
$mobile-button-font-size: 25px;
$mobile-button-padding: calc(($mobile-button-size - $mobile-button-font-size) / 2);

.c-header__mobile-buttons {
	//flex: 1 0 auto;
	text-align: right;

	@include bp("medium") {
		flex: 0 0 auto;
	}

	@include bp($header-bp) {
		display: none;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle
.c-header__nav-toggle {
	@include bp($header-bp) {
		display: none;
	}

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: 1px solid $mobile-button-color;

		&:nth-child(1) {
			top: $mobile-button-padding;
		}

		&:nth-child(2) {
			top: $mobile-button-padding + 12px;
		}

		&:nth-child(3) {
			top: $mobile-button-padding + 24px;
		}
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) {
				transform: translateY(12px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-12px) rotate(-45deg);
			}
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}

// Search Toggle
.c-header__search-toggle {
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;
	cursor: pointer;

	&[aria-expanded="true"] .iconf-search {
		&:before {
			content: $iconf-close;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}

	@include bp("medium") {
		margin-right: ($ssu * 1.5);
	}
}

.c-header__search-overlay {
	background-color: var(--secondary-a);
}

.c-header__search {
	@include bp-below($header-bp--large) {
		display: none;
	}
}


.c-header__search-input {
	@include font-base-16;
	@include placeholder-text($grey);
	display: block;
	padding: $ssu $bsu;
	width: 100%;
	margin: 0 auto;
	border: 1px solid $grey;
	border-radius: 4px;
	background: $white;

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

.c-header__search-btn {
	@include button-reset;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
	width: 50px;
	height: 50px;
	font-size: 16px;
	line-height: 1;
	color: $brand;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include link-transition;

	&:hover,
	&:focus {
		color: darken($brand, 10%);
		color: var(--secondary-a);
	}
}

.c-header__cta-btn {
	&.t-action--primary {
		&:hover,
		&:focus {
			background-color: var(--secondary-b);
			color: var(--secondary-a);
		}
	}
}
