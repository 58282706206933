.c-page-header {
	margin: $lsu 0;
}

.c-page-header__layout {
	@include layout-wrapper;
	.c-content-type-contactpage & {
		display: flex;
		flex-direction: column-reverse;
	}
}

.c-page-header__layout-inner {
	display: flex;
	max-width: 100%;
	flex-direction: column;
	align-items: flex-start;
	position: relative;

	@include bp("large") {
		padding-left: ($hsu - $bsu);
	}

	.c-content-type-detailpage & {
		max-width: $layout-wrapper-narrow-width - $bsu * 2;
		max-width: calc($layout-wrapper-narrow-width-vars - #{$bsu * 2});
	}


	&::before {

		@include bp("large") {
			width: 20px;
			height: 90px;
			content: "";
			display: inline-block;
			background-image: url(/Content/images/interface/bg/tgh/Intro_pattern.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: top center;
			position: absolute;
			left: 0;
			top: -$bsu;
		}
	}
}

.o-layout-header__lede {
	@include font-heading-24;

	@include bp("large") {
		@include font-heading-28;
	}

	.c-page-header__layout-inner & {
		flex: 1;
	}
}

.c-page-header__heading-wrapper {
	display: flex;
	flex-direction: column-reverse;
}

.c-page-header__heading {
	margin: $bsu 0 $ssu;
	display: block;
	font-style: normal;
	@include font-heading-16;

	@include bp("medium") {
		@include font-heading-24;
	}

	.no-mi & {
		display: block;
		margin-bottom: $lsu;

		&:last-child {
			margin-bottom: 0;
		}

		@include font-base-28sb;

		@include bp("medium") {
			@include font-base-32sb;
		}

		@include bp($hero-bp) {
			@include font-base-60sb;
		}
	}
}

.c-page-header__heading-mi {
	
	display: block;
	margin-bottom: $bsu;
	font-style: normal;
	margin-top: 0;

	&:last-child {
		margin-bottom: 0;
	}

	@include font-base-28sb;

	@include bp("medium") {
		@include font-base-32sb;
	}

	@include bp($hero-bp) {
		@include font-base-60sb;
	}
}

.c-page-header__date {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-14;
}

.c-page-header__authors {
	@include font-base-18;
}
