﻿// THEME

$white: #fff;
$black: #000;

$grey--lightest: #f9f9f9;
$grey--light: #efefef;
$grey: #d0d0d0;
$grey--dark: #666;
$grey--darkest: #333;

$carcoal--dark: #4A4A4A;
$carcoal: #231f20;

$blue: #00b3c4;

$red: #e73431;
$red--dark: #780c15;

$green--darkest:#28302A;
$green--dark: #2F4641;
$green--pale: #ACC0B3;
$green--grey: #EAEFEC;
$teal: #1FA2AC;

$brand: $green--pale;
$brand--alt: $green--dark;

$cyan--light: #f2fbfb;

$default: $brand;
$commercial: #5F6A71;
$innovation-hub: #0095C1;
$retail: #6C257B;
$residential: #265838;
$green-spaces: #6A8F40;
$inland-port: #b2946b;
$logistics: #931919;


// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: $error;

// Assign colours to common variables
$body-color: $grey--darkest;
$base-ui-color: $grey--light;

// Main Colours
$primary-a: $brand !default;
$primary-b: $white !default;
$secondary-a: $brand--alt !default;
$secondary-b: $white !default;

$supporting-1-a: $green--darkest;
$supporting-1-b: $white;

$supporting-2-a: #002953;
$supporting-2-b: #ffffff;

$link: $brand !default;
$link-hover: $brand--alt !default;
