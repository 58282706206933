﻿// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes

$base-font-size: 16px;
$base-line-height: 22px;

$weight--light: 300;
$weight--normal: 400;
$weight--semibold: 600;
$weight--bold: 700;

// Foobert
//
// Transformed: Generated by Font Squirrel (https://www.fontsquirrel.com) on June 15, 2022
//
// Licence: SIL OPEN FONT LICENSE Version 1.1
// Licence URL: /Content/fonts/tgh/roobert/OFL.txt

// @font-face {
//	font-family: 'roobertregular';
//	src: url('/Content/fonts/tgh/roobert-regular-webfont.woff2') format('woff2'), url('/Content/fonts/tgh/roobert-regular-webfont.woff') format('woff');
//	font-weight: normal;
//	font-style: normal;
//}

//////////


@font-face {
	font-family: 'roobertregular';
	src: url('../fonts/tgh/roobert/roobert-regular-webfont.woff2') format('woff2'),
		url('../fonts/tgh/roobert/roobert-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'roobertregular_italic';
	src: url('../fonts/tgh/roobert/roobert-regularitalic-webfont.woff2') format('woff2'),
		url('../fonts/tgh/roobert/roobert-regularitalic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'roobertsemibold';
	src: url('../fonts/tgh/roobert/roobert-semibold-webfont.woff2') format('woff2'),
		url('../fonts/tgh/roobert/roobert-semibold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'roobertsemibold_italic';
	src: url('../fonts/tgh/roobert/roobert-semibolditalic-webfont.woff2') format('woff2'),
		url('../fonts/tgh/roobert/roobert-semibolditalic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'domaine-text';
	src: url('../fonts/tgh/domaine-text/domaine-text-regular.woff2') format('woff2'),
		url('../fonts/tgh/domaine-text/DomaineText-Regular.otf') format('otf');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'domaine-text_italic';
	src: url('../fonts/tgh/domaine-text/domaine-text-regular-italic.woff2') format('woff2'),
		url('../fonts/tgh/domaine-text/DomaineText-RegularItalic.otf') format('otf');
	font-weight: normal;
	font-style: normal;
}

// Style declarations
//
//	.c-component__text {
//		@include font-base-12;
//	}

@mixin font-body-text {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(26px, 16px);
}

////////////////////////
// Base font variants //
////////////////////////

@mixin font-base-60sb {
	font-family: $base-font-family;
	font-size: 60px;
	font-weight: $weight--semibold;
	line-height: lh(72, 60);
	letter-spacing: 0.02em
}

@mixin font-base-52sb {
	font-family: $base-font-family;
	font-size: 52px;
	font-weight: $weight--semibold;
	line-height: lh(58, 52);
	letter-spacing: 0.02em
}


@mixin font-base-45sb {
	font-family: $base-font-family;
	font-size: 45px;
	font-weight: $weight--semibold;
	line-height: lh(52, 45);
	letter-spacing: 0.02em
}

@mixin font-base-36sb {
	font-family: $base-font-family;
	font-size: 36px;
	font-weight: $weight--semibold;
	line-height: 1.15;
	letter-spacing: 0.02em
}

@mixin font-base-32sb {
	font-family: $base-font-family;
	font-size: 32px;
	font-weight: $weight--semibold;
	line-height: 1.15;
	letter-spacing: 0.02em
}

@mixin font-base-28b {
	font-family: $base-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
	letter-spacing: 0.02em
}

@mixin font-base-28sb {
	font-family: $base-font-family;
	font-size: 28px;
	font-weight: $weight--semibold;
	line-height: lh(36, 28);
	letter-spacing: 0.02em
}

@mixin font-base-24b {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
	letter-spacing: 0.02em
}

@mixin font-base-24sb {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--semibold;
	line-height: 1.15;
	letter-spacing: 0.02em
}

@mixin font-base-22 {
	font-family: $base-font-family;
	font-size: 22px;
	font-weight: $weight--normal;
	line-height: 1.15;
	letter-spacing: 0.02em
}

@mixin font-base-20 {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
	letter-spacing: 0.02em
}

@mixin font-base-20-tall {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
	letter-spacing: 0.02em
}

@mixin font-base-20b {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
	letter-spacing: 0.02em
}

@mixin font-base-20sb {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--semibold;
	line-height: lh(28, 20);
	letter-spacing: 0.02em
}


@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.35;
	letter-spacing: 0.02em
}

@mixin font-base-18b {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
	letter-spacing: 0.02em
}

@mixin font-base-18sb {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--semibold;
	line-height: 1.35;
	letter-spacing: 0.02em
}

@mixin font-base-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-16b {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-16sb {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--semibold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.02em
}

@mixin font-base-14i {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
	font-style: italic;
	letter-spacing: 0.02em
}

@mixin font-base-14b {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.02em
}

@mixin font-base-14sb {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--semibold;
	line-height: 1.5;
	letter-spacing: 0.02em
}

@mixin font-base-14u {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: 1.15;
	letter-spacing: 0.02em
}

@mixin font-base-13 {
	font-family: $base-font-family;
	font-size: 13px;
	font-weight: $weight--normal;
	line-height: lh(23, 13);
	letter-spacing: 0.02em
}

@mixin font-base-13i {
	@include font-base-13;
	font-style:italic;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(17, 12);
	letter-spacing: 0.02em
}

@mixin font-base-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(17, 12);
	letter-spacing: 0.02em
}

@mixin font-base-12sb {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--semibold;
	line-height: lh(17, 12);
	letter-spacing: 0.02em
}

@mixin font-base-12bu {
	@include font-base-12b;
	text-transform: uppercase;
}

@mixin font-base-10b {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--bold;
	line-height: lh(14, 10);
	letter-spacing: 0.02em
}

@mixin font-base-10sb {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--semibold;
	line-height: lh(14, 10);
	letter-spacing: 0.02em
}


@mixin font-base-10 {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--normal;
	line-height: lh(14, 10);
	letter-spacing: 0.02em
}

///////////////////////////
// Heading font variants //
///////////////////////////
@mixin font-heading-90b {
	font-family: $heading-font-family;
	font-size: 90px;
	font-weight: $weight--bold;
	line-height: lh(60, 90);
	letter-spacing: 0.5px;
}

@mixin font-heading-72 {
	font-family: $heading-font-family;
	font-size: 72px;
	font-weight: $weight--normal;
	line-height: lh(80, 72);
	letter-spacing: 0.5px;
}

@mixin font-heading-60b {
	font-family: $heading-font-family;
	font-size: 60px;
	font-weight: $weight--bold;
	line-height: lh(72, 60);
	letter-spacing: 0.5px;
}

@mixin font-heading-60 {
	font-family: $heading-font-family;
	font-size: 60px;
	font-weight: $weight--normal;
	line-height: lh(72, 60);
	letter-spacing: 0.5px;
}

@mixin font-heading-45 {
	font-family: $heading-font-family;
	font-size: 45px;
	font-weight: $weight--normal;
	line-height: lh(52, 45);
	letter-spacing: 0.5px;
}

@mixin font-heading-45b {
	font-family: $heading-font-family;
	font-size: 45px;
	font-weight: $weight--bold;
	line-height: lh(52, 45);
	letter-spacing: 0.5px;
}

@mixin font-heading-36b {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
	letter-spacing: 0.5px;
}

@mixin font-heading-36 {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--normal;
	line-height: 1.15;
	letter-spacing: 0.5px;
}

@mixin font-heading-32 {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--normal;
	line-height: 1.15;
	letter-spacing: 0.5px;
}

@mixin font-heading-32b {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 1.15;
	letter-spacing: 0.5px;
}

@mixin font-heading-28b {
	font-family: $heading-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
	letter-spacing: 0.5px;
}

@mixin font-heading-28b--tall {
	@include font-heading-28b;
	text-transform: uppercase;
}

@mixin font-heading-28 {
	font-family: $heading-font-family;
	font-size: 28px;
	font-weight: $weight--normal;
	line-height: lh(36, 28);
	letter-spacing: 0.5px;
}

@mixin font-heading-24b {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.3;
	letter-spacing: 0.5px;
}

@mixin font-heading-24 {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--normal;
	line-height: 1.3;
	letter-spacing: 0.5px;
}

@mixin font-heading-22b {
	font-family: $heading-font-family;
	font-size: 22px;
	font-weight: $weight--bold;
	line-height: 1.3;
	letter-spacing: 0.5px;
}

@mixin font-heading-22 {
	font-family: $heading-font-family;
	font-size: 22px;
	font-weight: $weight--normal;
	line-height: 1.3;
	letter-spacing: 0.5px;
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.3;
	letter-spacing: 0.5px;
}

@mixin font-heading-20-tall {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.5px;
}

@mixin font-heading-20b {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: 1.3;
	letter-spacing: 0.5px;
}

@mixin font-heading-20b--tall {
	@include font-heading-20-tall;
	font-weight: $weight--bold;
}

@mixin font-heading-20bu {
	@include font-heading-20b;
	text-transform: uppercase;
}

@mixin font-heading-20i {
	@include font-heading-20;
	font-style: italic;
}

@mixin font-heading-18b {
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
	letter-spacing: 0.5px;
}

@mixin font-heading-18 {
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.35;
	letter-spacing: 0.5px;
}


@mixin font-heading-16b {
	font-family: $heading-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: lh(20, 16);
	letter-spacing: 0.5px;
}

@mixin font-heading-16 {
	font-family: $heading-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(20, 16);
	letter-spacing: 0.5px;
}


@mixin font-heading-16bu {
	@include font-heading-16b;
	text-transform: uppercase;
}

@mixin font-heading-14b {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.5px;
}

@mixin font-heading-14 {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
}

@mixin font-heading-13i {
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: $weight--normal;
	line-height: 1.5;
	font-style: italic;
	letter-spacing: 0.5px;
}

@mixin font-heading-13 {
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.5px;
}


@mixin font-heading-12 {
	font-family: $heading-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(14, 12);
	letter-spacing: 0.5px;
}

@mixin font-heading-12b {
	font-family: $heading-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(14, 12);
	letter-spacing: 0.5px;
}

@mixin font-heading-10 {
	font-family: $heading-font-family;
	font-size: 10px;
	font-weight: $weight--normal;
	line-height: lh(14, 10);
	letter-spacing: 0.5px;
}

@mixin font-heading-10b {
	font-family: $heading-font-family;
	font-size: 10px;
	font-weight: $weight--bold;
	line-height: lh(14, 10);
	letter-spacing: 0.5px;
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
