// CONTROL.LATEST-INSIGHTS

$latest-insights-bp: "large";

.c-latest-insights {
	@include control;
	padding: $lsu 0;
	padding: var(--control-gap) 0;

	background: $green--grey;
}

.c-latest-insights__header {
	@include layout-wrapper;

	@include bp-below($latest-insights-bp) {
		margin-bottom: $bsu;
		.c-latest-insights__buttons {
			display: none;
		}
	}
	@include bp($latest-insights-bp) {
		display: flex;
		align-items: center;
		margin-bottom: $lsu;
	}
}

.c-latest-insights__body {
	@include layout-split("left", 100%);
	overflow: hidden;
}

.c-latest-insights__footer {
	@include layout-wrapper;
	@include bp($latest-insights-bp) {
		display: none;
	}
}

////////////
// HEADER //
////////////

.c-latest-insights__heading-wrapper {
	display: flex;
	flex-direction: column-reverse;
	margin-right: $lsu;
}

.c-latest-insights__heading {

	@include font-heading-18;
	display: inline-block;

	&.t-text {
		color: $grey--dark;
	}
}

.c-latest-insights__heading-mi {
	@include font-base-36sb;
	display: inline-block;
	margin-bottom: $tsu;
	font-style: normal;
}

// Use separate block to ensure overrides apply correctly
.c-latest-insights__heading {
	display: block;
	margin-right: $lsu;
	margin-bottom: $ssu;

	@include bp-below($latest-insights-bp) {
		margin-bottom: $ssu;
	}
}

.c-latest-insights__links {
	@include flex-extend;
}

.c-latest-insights__link {

	@include font-heading-14;
	@include bp($latest-insights-bp) {
		@include font-heading-16;
	}

	
	@include link-fancy(before);
	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		margin-left: $ssu;
	}
}

.c-latest-insights__buttons {
	@include bp($latest-insights-bp) {
		.c-latest-insights__header & {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
		}
	}
}

.c-latest-insights__button {
	margin-right: $bsu;
	&:last-child {
		margin-right: 0;
	}

	@include bp($latest-insights-bp) {
		@include button("medium", true);
	}
}

//////////
// BODY //
//////////
.c-latest-insights__rotator {
	display: flex;
	margin-left: -$lsu;

	@include bp-below($latest-insights-bp) {
		margin-left: $ssu - $lsu;
	}

	position: relative;

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: $lsu;
		background: linear-gradient(90deg, rgba($green--grey, 1), rgba($green--grey, 0));
	}

	&.flickity-enabled {
		display: block;

		.flickity-viewport[style*="height:"] .c-latest-insights__rotator-item {
			height: 100%;
		}
	}
}


.c-latest-insights__pod--no-image {
	.c-latest-insights__pod-body {
		background-image: url("/Content/images/interface/bg/tgh/PatternOnNew_DarkPod.svg");
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: contain;

		&.theme__light {
			background-image: url("/Content/images/interface/bg/tgh/PatternOnNew_WhitePod.svg");
		}

		&.theme__gold {
			background-color: var(--secondary-a);
			background-image: url("/Content/images/interface/bg/tgh/PatternOnNew_DarkPod.svg");
		}
	}
}

.c-latest-insights__pod-tag {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;

	padding: $tsu $ssu;
	border-radius: 0;

	@include font-base-12;
}

.c-latest-insights__pod-body {
	flex-grow: 1;
	padding: $bsu;

	@include link-transition(color);

	.theme__gold & {
		color: var(--secondary-b);
	}
}

.c-latest-insights__pod-date {

	@include bp-below($latest-insights-bp) {
		@include font-base-12;
	}
	@include bp($latest-insights-bp) {
		@include font-heading-14;
	}
}

.c-latest-insights__pod-title {

	@include bp-below($latest-insights-bp) {
		@include font-heading-18;
	}
	@include bp($latest-insights-bp) {
		@include font-heading-22;
	}

	.theme__gold & {
		color: var(--secondary-b);
	}
}

.c-latest-insights__pod-title-text {
	@include link-arrow;
}

.c-latest-insights__pod-text {
	.theme__gold & {
		color: var(--secondary-b);
	}
}
