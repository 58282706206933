﻿// CONTROL.ICON-LINKS

.c-icon-links {
	@include flex-extend;
	flex-direction: column;
	background-color: $green--grey;

	@include bp("large") {
		flex-direction: row;
	}
}

.c-icon-links-wrap {
	@include layout-wrapper;
	width: 100%;
	flex-direction: column;
	display: flex;
	//justify-content: center;
	align-items: center;
	text-align: center;

	@include bp("large") {
		flex-direction: row;
		text-align: left;
	}
}

.c-icon-links__heading-wrap {
	width: 100%;
	padding: $bsu 0;

	@include bp("large") {
		width: 25%;
		padding-top: $lsu;
		padding-bottom: $lsu;
		position: relative;
	}
}

.c-icon-links__headings {
	@include bp("large") {
		//@include layout-split(left, 30%);
	}

	@include bp(1920px) {
		//@include layout-split(left, 35%);
	}
}

.c-icon-links__heading {
	@include font-heading-18;
	color: var(--secondary-a);
	display: block;
	margin-bottom: $tsu;

	@include bp("large") {
		@include font-heading-20;
	}

	@include bp("extra-large") {
	   // margin-left: $lsu;
	}
}

.c-icon-links__heading-mi {
	@include font-base-24sb;

	@include bp("large") {
		@include font-base-36sb;
		margin-left: 0;
	}

	@include bp("extra-large") {
	   // margin-left: $lsu;
	}
}

.c-icon-links__list-wrap {
	background-color: $green--grey;
	padding: $bsu 0;
	width: 100%;

	@include bp("large") {
		width: 75%;
		padding: $lsu 0;
	}
}

.c-icon-links__list {
	@include flex-extend;
	gap: $bsu;
	flex-direction: column;
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include bp("medium") {
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		grid-template-columns: repeat(5, 1fr);
	}
}

.c-icon-link__item-link {
	@include flex-extend;
	flex-direction: column;
	gap: $ssu;
	align-items: center;

	@include bp("medium") {
		justify-content: center;
		justify-content: space-between;
		text-align: center;
	}

	&:first-child {
		@include bp("large") {
			//margin-left: 10%;
		}
	}

	&:last-child {
		@include bp("large") {
			// margin-right: $lsu;
		}
	}

	&.t-link:hover,
	&.t-link:focus {
		text-decoration: none;
	}
}


.c-icon-link__item-icon {
	font-size: 50px;

	@include bp("medium") {
		font-size: 40px;
	}
}

.c-icon-link__item-text-wrap {
	display: flex;
	flex-direction: column;
}

.c-icon-link__item-text {
	@include font-heading-14;

	&.no-mi {
		@include font-base-18sb;
	}
}

.c-icon-link__item-text-mi {
	@include font-base-18sb;
}