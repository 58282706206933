﻿// CONTROL.PODS

$pod-bp: "medium";

.c-pod {}

.c-pod__head {

	&::before,
	&::after {
		display: none !important;
	}

	&:hover,
	&:focus {

		.c-pod__figure:after {
			background: var(--primary-a);
			opacity: 0.8;
			transition: all 0.3s;
		}
	}
}

.c-pod__figure {
	display: block;
	height: 100%;
	position: relative;
	z-index: 1;

	&::after {
		@include link-transition(all, 0.3s);
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(0deg, $black 0%, transparent 200px);
	}
}

.c-pod__no-figure {
	&::after {
		background-image: none;
	}
}

.c-pod__image {
	@include link-transition;
	// Height inherited instead from pod
	.c-pod & {
		padding-bottom: 0;
		height: 100%;
	}
}

.c-pod__title {
	display: inline-block;
	margin: $ssu $bsu;
	padding: $ssu calc(1em + #{$tsu}) $ssu 0;
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;

	@include font-heading-20;
	@include link-transition;

	&.t-text--sec--active {
		&:hover,
		&:focus {
			color: $white;
		}
	}
}

.c-pod__title-text {
	&.t-text--sec--active {
		&:hover,
		&:focus {
			color:$white;
		}
	}
}