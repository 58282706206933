// OBJECT.BTN

// Shared button styles

@mixin button-size($size: "medium", $icon: false) {
	@if ($icon == true) {
		@include button-icon-size($size);
	} @else {
		@if ($size == "large") {
			@include font-base-16;
			padding: 15px $lsu;
		} @else if ($size == "medium") {
			@include font-base-16;
			padding: 10px $lsu;
		} @else if ($size == "small") {
			@include font-base-14;
			padding: 10px $bsu;
		} @else if ($size == "smallest") {
			@include font-base-14;
			padding: 5px 14px;
		} @else {
			@warn "Unknown button size #{$size}.";
		}
	}
}

@mixin button-icon-size($size: "medium") {
	@if ($size == "large") {
		height: 74px;
		width: 74px;
		font-size: 28px;
		line-height: #{74px - (23px * 2)};
		padding: 23px;
	} @else if ($size == "medium") {
		height: 54px;
		width: 54px;
		font-size: 24px;
		line-height: #{54px - (15px * 2)};
		padding: 15px;
	} @else if ($size == "small") {
		height: 40px;
		width: 40px;
		font-size: 16px;
		line-height: #{40px - (11px * 2)};
		padding: 11px;
	} @else if ($size == "smallest") {
		height: 30px;
		width: 30px;
		font-size: 14px;
		line-height: #{30px - (6px * 2)};
		padding: 6px;
	}
}

@mixin button($size: "medium", $icon: false) {
	@include link-transition;
	display: inline-block;

	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@include button-size($size);
	}

	border: 0;
	border-radius: 50px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;

	@include bp("extra-large") {
		white-space: nowrap;
	}

	&:disabled {
		cursor: not-allowed;
		background: $white !important;
		color: $grey !important;
		border: none !important;
		box-shadow: inset 0 0 0 1px $grey--light !important;
	}
}

@mixin button--text {
	padding: 0;
	border: none;
	background-color: transparent;
}

.o-btn {
	@include button;
}

// For vertical alignment of buttons with icon and text
.o-btn__icon,
.o-btn__text {
	display: inline-block;
	vertical-align: middle;
}
.o-btn__icon + .o-btn__text,
.o-btn__text + .o-btn__icon {
	margin-left: $ssu;
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--nowrap {
	white-space: nowrap;
}

.o-btn--reset {
	padding: 0;
	border: 0;
	background: none;
}


// Buttons that contain only an icon use different padding
// Apply .o-btn--icon in addition to a theme and a size
.o-btn--icon {
	&.o-btn--large {
		@include button-icon-size("large");
	}
	&, &.o-btn--medium {
		@include button-icon-size("medium");
	}
	&.o-btn--small {
		@include button-icon-size("small");
	}
	&.o-btn--smallest {
		@include button-icon-size("smallest");
	}
}

////////////
// THEMES //
////////////

// See ~/Views/Housing/ThemeStyle.cshtml for button theme CSS

// Text
.o-btn--text {
	@include button--text;
}

.o-btn--icon-before {
	position: relative;
	padding-left: 2em;
}

.o-btn--icon-before [class*="iconf"],
.o-btn--icon-after [class*="iconf"] {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.o-btn--icon-before [class*="iconf"] {
	left: 0;
}

.o-btn--icon-after [class*="iconf"] {
	right: 0;
}

///////////
// SIZES //
///////////
.o-btn--large {
	@include button-size("large");
}

.o-btn--medium {
	@include button-size("medium");
}

.o-btn--small {
	@include button-size("small");
}

.o-btn--smallest {
	@include button-size("smallest");
}
