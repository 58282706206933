﻿// CONTROL.TABS


.c-tabs__link {
	&:hover,
	&:focus {
		background-color: $green--grey;
	}
}

.c-tabs__link-text {
	@include font-heading-12;

	@include bp("medium") {
		@include font-heading-14;
	}

	&.no-mi {
		@include bp("medium") {
			@include font-base-18sb;
		}

		@include bp-below("medium") {
			@include font-base-14sb;
		}
	}
}

.c-tabs__link-text-mi {
	@include bp("medium") {
		@include font-base-18sb;
	}

	@include bp-below("medium") {
		@include font-base-14sb;
	}
}