﻿// CONTROL.TABBED-CONTENT

.c-tabbed-content__heading {
    @include font-base-28sb;

    @include bp("medium") {
        @include font-base-36sb;
    }

    @include bp("large") {
        @include font-base-52sb;
    }

    @include bp("extra-large") {
        @include font-base-60sb;
    }
}

.c-tabbed-content__eyebrow {
    @include font-heading-16;

    @include bp("large") {
        @include font-heading-18;
    }
}

.c-tabbed-content__intro {
    @include font-heading-16;

    @include bp("medium") {
        @include font-heading-18;
    }

    @include bp("large") {
        @include font-heading-20;
    }
}