/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

$megamenu__column-gap: 40px;
$megamenu__column-number: 4;
$megamenu__column-number--with-promos: 2;
$megamenu__column-number--with-promos-vars: calc(4 - var(--megamenu-number-promos, 2));

$megamenu-bp: $header-bp;

.c-megamenu {
	background: $green--darkest;

	&[aria-expanded="true"],
	[aria-expanded="true"] > & {
		display: block;

		@include bp-below($megamenu-bp) {
			padding: $ssu $bsu;
		}
	}

	@include bp($megamenu-bp) {
		background: var(--secondary-a);
	}
}

.c-megamenu__body {
	@include bp($megamenu-bp) {
		@include flex-extend;
	}
}

.c-megamenu__body--single-promo {
	--megamenu-number-promos: 1;
}

.c-megamenu__intro {
	border: none;
}

.c-megamenu__intro-heading {
	@include font-heading-16;

	@include link-fancy;

	&::after {
		display: none;
	}

	@include bp("large") {
		display: flex;
		flex-direction: row;
		gap: $bsu;
	}

	.c-megamenu__link-icon {
		@include bp("large") {
			display: inline-flex;
			align-self: flex-end;
			margin-bottom: $tsu;
			position: static;
		}
	}
}

.c-megamenu__intro-heading-wrap {
	display: flex;
	flex-direction: column-reverse;
}


.c-megamenu__intro-heading-mi {
	@include font-base-36sb;
	display: block;
}

.c-megamenu__link {
	@include font-heading-16;

	@include bp($megamenu-bp) {
		@include font-heading-18;
		padding: $ssu ($bsu * 1.5) $ssu 0;

		&::after {
			height: 2px;
			background-color: var(--primary-a);
		}
	}

	&.t-bg__text {
		&:hover,
		&:focus {
			color: $green--pale;
		}
	}
}

.c-megamenu__link-icon {

	display: none;

	@include bp($megamenu-bp) {
		display: flex;
	}
}


.c-megamenu__promo-title {
	@include font-base-16;
}


.c-megamenu__promo-item {
	@include link-transition;

	&:hover,
	&:focus {
		.c-megamenu__promo-image {
			transform: scale(1.1);
		}

		.c-megamenu__promo-title-text::after {
			bottom: 0;
		}
	}
}