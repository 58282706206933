// COMPONENT.FOOTER

@use "sass:math";

$footer-top-bp: "medium";
$footer-bottom-bp: "medium";

$footer-padding-left: 200px;

.c-footer {
	background: $supporting-1-a;
	color: $supporting-1-b;
	background: var(--supporting-1-a);
	color: var(--supporting-1-b);

	overflow:hidden;
}

//////////////////////
// FOOTER CONTAINER //
/////////////////////

.c-footer-container {
	@include flex-extend;
	flex-direction: column;

	@include bp("large") {
		flex-direction: row;
	}
}

.c-footer__left {
	width: 100%;
	background-color: var(--secondary-a);

	@include bp("large") {
		width: 15%;
	}

	@include bp("extra-large") {
		width: 25%;
	}
}

.c-footer__left-layout {
	@include bp("large") {
		@include layout-split(left, 15%);
		display: flex;
		height: 100%;
		justify-content: flex-end;
	}

	@include bp("extra-large") {
		@include layout-split(left, 25%);
	}
}

.c-footer__logo {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	@include bp("large") {
		padding-right: 20px;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		transform-origin: center center;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-image: url(/Content/images/interface/bg/down_darkgreen.svg);
		right: 0;
		top: 80%;
		width: 100%;
		height: 100%;


		@include bp("large") {
			background-image: url(/Content/images/interface/bg/left-green.svg);
			right: -60px;
			top: 0;
			width: 70px;
			height: 100%;
		}
	}
}

.c-footer__logo-img {

	.c-footer__logo & {
		position: relative;
		z-index: 1;
		max-width: inherit;
		max-height: 100%;

		@include bp-below("large") {
			top: $lsu;
		}

		@include bp("large") {
			width: 120px;
		}

		@include bp("extra-large") {
			width: auto;
		}
	}
}

.c-footer__right {
	width: 100%;
	background-color: var(--supporting-1-a);
	padding-top: 120px;

	@include bp("large") {
		padding-top: 0;
		width: 85%;
		padding-left: 100px;
	}

	@include bp("extra-large") {
		width: 75%;
	}
}

.c-footer__right-layout {
	@include bp("large") {
		@include layout-split(right, 85%);
	}
	
	@include bp("extra-large") {
		@include layout-split(right, 75%);
	}
}

.c-footer__container {
	@include layout-wrapper;
	@include flex-extend;
	flex-direction: column;
	background: $supporting-1-a;
	color: $supporting-1-b;
	background: var(--supporting-1-a);
	color: var(--supporting-1-b);
}


////////////////
// FOOTER TOP //
////////////////

.c-footer__top {
	position: relative;
}

.c-footer__top-container {
	@include layout-wrapper;
	padding-top: ($bsu + $tsu);
	padding-bottom: ($bsu + $tsu);

	@include flex-extend;
	gap: $bsu;

	@include bp-below($footer-top-bp) {
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	align-items: center;
	width: 100%;
	overflow: hidden;
	position: relative;
	flex-wrap: wrap;

	@include bp("large") {
		padding-left: 0;
		padding-right: $bsu;
		padding-top: $lsu;
		padding-bottom: $lsu;
		align-items: center;
		justify-content: space-between;

		&::before {
			display: none;
		}
	}

	@include bp("extra-large") {
		padding-left: 0;
		padding-right: 0;
	}
}

.c-footer__cta {
	display: flex;
	flex-direction: column;
	gap: $bsu;
	align-items: center;
	justify-content: center;
	width: 100%;
	text-align: center;

	@include bp("large") {
		flex-direction: row;
		width: auto;
		text-align: left;
		gap: $lsu;
	}
}

.c-footer__top-text__wrapper {
	@include bp("large"){
		max-width: 650px;
	}
}

.c-footer__top-text {
	display: block;
	position: relative;
	z-index: 1;
	margin-bottom: $ssu;

	@include bp-below($footer-top-bp) {
		margin-bottom: $bsu;
		text-align: center;
	}

	@include bp($footer-top-bp) {
		margin-right: 0;
	}

	@include font-heading-14;

	@include bp($footer-top-bp) {
		@include font-heading-14;
	}
}

.c-footer__top-text-mi {
	@include font-base-24sb;
	display: block;
	margin-bottom: $ssu;
	font-style: normal;
}

.c-footer__top-button {
	white-space: nowrap;


	@include bp-below($footer-top-bp) {
		@include button("small");
		padding: $ssu $lsu;
		border: 1px solid var(--secondary-b);
	}

	@include bp($footer-top-bp) {
		@include button("small");
		padding: $ssu $lsu;
		border: 1px solid var(--secondary-b);
	}
}

.c-footer__btn-text {
	@include button--text;
	color: var(--primary-b);
	display: inline-flex;
	align-items: center;

	&.c-footer__btn {
		display: inline-flex;
		gap: $ssu;
		padding: $tsu;
		@include font-base-16;

		@include bp-below("large") {
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}

		@include bp("large") {
			margin-bottom: 0;
		}
	}

	&:hover,
	&:focus {
		color: var(--primary-a);
	}
}

.c-footer__btn-icon {
	@include bp-below("large") {
		font-size: 30px;
	}
}

///////////////////
// FOOTER BOTTOM //
///////////////////
.c-footer__right-bottom {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: $bsu;
	padding: $bsu;

	@include bp("large") {
		flex-direction: row;
		padding-left: 0;
	}
}

.c-footer__bottom {
	background: $supporting-1-a;
	color: $supporting-1-b;
	background: var(--supporting-1-a);
	color: var(--supporting-1-b);

	@include bp-below($footer-bottom-bp) {
		padding: ($bsu * 1.5) 0;
	}

	@include bp($footer-bottom-bp) {
		padding: $hsu 0;
	}

	@include bp("large") {
		padding: $hsu 0 $lsu;
	}
}

.c-footer__bottom-layout {
	@include layout-wrapper;

	@include bp("large") {
		padding-left: calc($footer-padding-left / 2);
	}

	@include bp("extra-large") {
		padding-left: $footer-padding-left;
	}
}

.c-footer__bottom-container {

	display: grid;
	grid-template-areas:
		"logos"
		"btns"
		"legal";

	grid-row-gap: $bsu;
	grid-column-gap: $lsu;

	@include bp($footer-bottom-bp) {
		grid-template-areas:
			"logos logos"
			"btns btns"
			"legal legal";

		grid-row-gap: $lsu;
	}

	@include bp("large") {
		grid-template-columns: 50%;
		grid-template-areas:
			"logos btns"
			"legal legal";
	}

	@include bp("extra-large") {
		grid-template-columns: inherit;
	}

}

// Logo List //
.c-footer__logo-items {
	grid-area: logos;
	display: flex;
	justify-content: center;
	align-items: center;

	@include bp("large") {
		justify-content: flex-start;
	}
}

.c-footer__logo-item {
	max-width: 90px;
	margin-right: $bsu;

	&:last-child {
		margin-right: 0;
	}

	@include bp("medium") {
		max-width: unquote("min(130px, 100%)");
	}

	@include bp("large") {
		margin-right: $lsu;
	}

	@include bp("extra-large") {
		width: auto;
		max-width: 140px;
	}
}

.c-footer__logo-img {
	max-width: 100%;
	max-height: 70px;
}

// Button List //
.c-footer__btn-list {
	flex: auto;

	@include bp-below("large") {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas: "footer-btn-phone footer-btn-email" "footer-btn-cta footer-btn-cta";
	}

	@include bp("large") {
		align-items: center;
	}
}

.c-footer__btn {
	margin-right: $bsu;
	margin-bottom: $bsu;

	&:last-child {
		margin-right: 0;
	}

	@include bp-below($footer-top-bp) {
		@include button("small");
		padding: $ssu $lsu;
		width: 100%;
		margin-right: 0;
	}

	@include bp("large") {
		@include button("small");
		padding: 10px 40px;
		margin-bottom: 0;
	}
}

.c-footer__btn-cta {
	grid-area: footer-btn-cta;
	text-align: center;
}

.c-footer__btn-phone {
	grid-area: footer-btn-phone;
}

.c-footer__btn-email {
	grid-area: footer-btn-email;
}

// Address //
.c-footer__address {
	grid-area: address;

	a {
		color: inherit;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	display: flex;
	flex-wrap: wrap;
	margin-left: -$lsu;
	margin-top: -$bsu;
}

.c-footer__address-item {
	padding-left: $lsu;
	padding-top: $bsu;
	@include bp-below($footer-bottom-bp) {
		width: 50%;
	}
}

.c-footer__address-item-title {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20;
}

.c-footer__address-item-text {
	@include font-base-14;

	> :last-child {
		margin-bottom: 0;
	}
}

// Social //

.c-footer__social {
	margin: $ssu auto;

	@include bp("large") {
		margin-left: 0;
		margin-right: $bsu;
	}
}

// Legal //
.c-footer__legal {
	grid-area: legal;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	@include bp("medium") {
		justify-content: center;
	}

	@include bp("large") {
		flex-direction: row;
		justify-content: space-between;
	}
}

.c-footer__legal-links {
	@include list-reset;

	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	@include bp-below($footer-bottom-bp) {
		justify-content: flex-start;
		margin: 0;
		width: 100%;
	}
}

.c-footer__legal-links-item {
	margin-bottom: $ssu;
	margin-right: $bsu;

	@include bp-below($footer-bottom-bp) {
		margin-left: $bsu;
	}

	&:first-child {
		width: auto;

		@include bp($footer-bottom-bp) {
			width: auto;
		}
	}

	&:last-child {
		@include bp($footer-bottom-bp) {
			margin-right: 0;
		}
	}
}

.c-footer__legal-link {
	display: block;

	@include font-base-12;
	color: inherit;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	display: block;

	@include font-base-12;
	color: inherit;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__legal-copyright {
	display: block;

	@include font-base-12;
}

// Back to top //
.c-footer-back-to-top {
	@include button("small", true);
	@include bp("large") {
		@include button-size("medium", true);
	}

	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	z-index: $z-back-to-top;

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear, visibility 0s linear 0.5s;
	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;

				transform: translateY(-50%);
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
