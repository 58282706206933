﻿// Modal

@use "sass:math";

.c-feature-modal__close {

	@include font-heading-18;

	&.t-link {
		color: var(--primary-a);
	}
}

.c-feature-modal__close-icon {
	margin-left: $ssu;
}

.c-feature-modal__title {
	@include font-heading-24;
}

.c-feature-modal__subtitle {
	@include font-base-16;
	color: var(--primary-a);
}


.c-feature-modal__cta {

	&.t-text--sec {
		color: var(--primary-a);
	}
}

.c-feature-modal__cta-text {
	@include font-base-24b;
}