﻿// MACRO.BLOCKQUOTE

.m-quote {
	@include body-text__figure;
	padding-left: $bsu + 4px;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 4px;
		height: 100%;
	}

	&.t-before-bg--sup-1 {
		&::before {
			background-color: $primary-a;
			background-color: var(--primary-a);
		}
	}
}

.m-quote__text {
	@include font-heading-20;
}

.m-quote__citation {
	@include font-base-16;
	font-style: normal;
}
