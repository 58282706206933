// COMPONENT.RELATED-ITEMS

.c-related-items__intro {
	margin-bottom: $bsu;

	p {
		@include font-body-text;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-related-item {
	margin-bottom: $tsu;
}

.c-related-item__link {
	border: 1px solid $green--pale;
	background-color: $white;
	color: $grey;


	&:hover,
	&:focus {
		border-color: $green--grey;
		background-color: $green--grey;
		color: (--primary-a);
	}

	&.t-border-hover {
		&:hover,
		&:focus {
			border-color: $green--grey;
		}
	}
}

.c-related-item__text {
	@include font-heading-16;
	color: $grey;

	&.t-bg__text--rev {
		color: $grey--dark;

		.c-related-item__link:hover &{
			color: var(--primary-a);
		}
	}
}



.c-related-item__meta {
	@include font-base-10;
	display: block;
	margin-top: $tsu;
	color: $grey--dark;
}
