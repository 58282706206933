@charset "UTF-8";

// For an explanation of the folder structure and namespacing check the wiki: https://terabyte.atlassian.net/wiki/spaces/TBWS/pages/94670004/CSS+Framework
$base-font-family: "roobertregular", Arial, Helvetica, sans-serif;
$heading-font-family: "domaine-text", Arial, Helvetica, sans-serif;

//@import "../fonts/font-icons/base/variables";
@import "main";
//@import "../fonts/font-icons/base/style.scss";

// Overrides
@import "theme/tgh/tgh.theme";
@import "theme/tgh/tgh.base.typography";

@import "theme/tgh/tgh.object.btn";
@import "theme/tgh/tgh.object.control";
@import "theme/tgh/tgh.object.body-text";

@import "theme/tgh/tgh.plugins.flickity";

@import "theme/tgh/tgh.layout.page";

@import "theme/tgh/tgh.control.cta";
@import "theme/tgh/tgh.control.related-items";
@import "theme/tgh/tgh.control.form";
@import "theme/tgh/tgh.control.embed";
@import "theme/tgh/tgh.control.expand-collapse";
@import "theme/tgh/tgh.control.image-feature";
@import "theme/tgh/tgh.control.faq";
@import "theme/tgh/tgh.control.case-study";
@import "theme/tgh/tgh.control.people";
@import "theme/tgh/tgh.control.listing-stacked";
@import "theme/tgh/tgh.control.pods";
@import "theme/tgh/tgh.control.explore-pods";
@import "theme/tgh/tgh.control.latest-insights";
@import "theme/tgh/tgh.control.statistics";
@import "theme/tgh/tgh.control.image";
@import "theme/tgh/tgh.control.image-serires";
@import "theme/tgh/tgh.control.dual-cta";
@import "theme/tgh/tgh.control.video";
@import "theme/tgh/tgh.control.subnav";
@import "theme/tgh/tgh.control.news-listing";
@import "theme/tgh/tgh.control.filter";

@import "theme/tgh/tgh.macro.quote";

@import "theme/tgh/tgh.component.share";
@import "theme/tgh/tgh.component.page-header";
@import "theme/tgh/tgh.component.feature-modal";
@import "theme/tgh/tgh.component.header";
@import "theme/tgh/tgh.component.navigation";
@import "theme/tgh/tgh.component.megamenu";
@import "theme/tgh/tgh.component.footer";
@import "theme/tgh/tgh.component.hero";
@import "theme/tgh/tgh.component.pagination";
@import "theme/tgh/tgh.component.breadcrumb";
@import "theme/tgh/tgh.component.search-result";
@import "theme/tgh/tgh.control.our-story";
@import "theme/tgh/tgh.control.tabbed-content";
@import "theme/tgh/tgh.control.tabs";
@import "theme/tgh/tgh.control.feature-carousel";

@import "components/_component.popup-modal";

// Custom components
@import "controls/control.icon-links";
@import "controls/control.job-adder";
