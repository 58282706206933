﻿// CONTROL.OUR-STORY

.c-our-story {
	background-color: $green--pale;
}

.c-our-story__content {

	@include bp(1025px) {
		@include layout-split(right, 50%);
	}
}

.c-our-story__eyebrow {
	@include font-heading-18;
}

.c-our-story__heading-mi {
	@include font-base-28sb;

	@include bp("medium") {
		@include font-base-36sb;
	}

	@include bp(1025px) {
		@include font-base-45sb;
	}
}

.c-our-story__heading {
	@include font-heading-28;

	@include bp("medium") {
		@include font-heading-36;
	}

	@include bp(1025px) {
		@include font-heading-45;
	}
}

.c-our-story__body {
	@include font-base-16;
}

.c-our-story__link {
	@include font-heading-18;
}