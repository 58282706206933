// CONTROL.NEWS-LISTING

.c-news-listing__body {
	background: $green--grey;
}

////////////
// FILTER //
////////////

.c-news-listing__filter-heading {
	@include font-heading-20;
}


//////////
// ITEM //
//////////

.c-news-listing__item-body {
	.c-news-listing__item--no-image & {
		background-size: contain;
		padding-bottom: 130px;

		&.theme__dark {
			background-image: url("/content/images/interface/bg/tgh/PatternOnNew_DarkPod.svg");
		}

		&.theme__light {
			background-image: url("/content/images/interface/bg/tgh/PatternOnNew_WhitePod.svg");
		}

		&.theme__gold {
			background-image: url("/content/images/interface/bg/tgh/PatternOnNew_WhitePod.svg");
		}
	}
}

.c-news-listing__item-heading {
	@include font-heading-18;

	@include bp($news-listing-bp) {
		@include font-heading-20;

		.c-news-listing__list-item--featured & {
			@include font-heading-24;
		}
	}
}