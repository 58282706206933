// CONTROL.CASE-STUDY

@use "sass:math";

$case-study-bp: "large";

@mixin triangle($position) {
	position: relative;

	&::before {


		@include bp("large") {
			content: "";
			display: block;
			position: absolute;
			width: 70px;
			height: 100%;
			transform-origin: center center;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url(/Content/images/interface/bg/left.svg);
			transform: rotate(0);
		}
	}

	&::before {
		top: 0;
	}

	@if( $position == left) {
		&::before {
			left: -50px;
			transform: rotate(180deg);
		}
	}

	@else {
		&::before {
			left: inherit;
			right: -50px;
		}
	}
}

.c-case-study {
	@include control;
}

.c-case-study__layout {
	@include layout-wrapper;

	@include bp($case-study-bp) {
		@include flex-extend;
		align-items: stretch;
	}

	&.c-case-study__layout--right {
		@include bp($case-study-bp) {
			flex-direction: row-reverse;
		}
	}
}

.c-case-study__figure {
	@include bp($case-study-bp) {
		flex: 1 0 60%;
	}
}

.c-case-study__image {}

.c-case-study__body {
	@include font-body-text;
	padding: $bsu;
	background-color: $grey--lightest;
	border-radius: 0;
	background-image: none;

	@include bp($case-study-bp) {
		flex: 1 0 40%;
		padding: $lsu;
		border-radius: 0;
		margin: $lsu 0 $lsu -#{$bsu * 1.5};
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		.c-case-study__layout--right & {
			margin: $lsu -#{$bsu * 1.5} $lsu 0;
		}
	}

	@include triangle(left);

	.c-case-study__layout--right & {
		@include triangle(right);
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.c-case-study__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20;
	@include bp($case-study-bp) {
		@include font-heading-24;
	}
}

.c-case-study__text {
	display: block;
	margin-bottom: $bsu;

	@include font-body-text;
}

.c-case-study__link {
	@include button("small");
	padding-left: $bsu;
	padding-right: $bsu;
	border: 1px solid var(--primary-b);

	&.t-action--primary {
		background-color: var(--secondary-a);
		border-color: var(--secondary-a);

		&:hover,
		&:focus {
			border-color: var(--secondary-b);
			background-color: var(--primary-a);
		}
	}

	&:hover,
	&:focus {
		border-color: var(--secondary-a);
	}
}

.c-case-study__video {
	display: block;
	cursor: pointer;

	padding-bottom: percentage(calc(9 / 16));
}

.c-case-study__video-icon {
	@include abs-center;
	@include circle(120px);
	//border-radius: 4px;
	@include link-transition;

	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		@include pseudo-element;
		@include font-icon;
		content: $iconf-play;

		font-size: 60px;
	}
}

