// CONTROL.dual-CTA

$dual-cta-bp: 1640px;

.c-dual-cta {}

.c-dual-cta__layout {}

.c-dual-cta__item {
	@include flex-extend;

	@include bp-below("small") {
		flex-direction: column-reverse;
		align-items: center;
	}

	background: $green--grey;

	.c-dual-cta__heading,
	.c-dual-cta__text {
		color: var(--secondary-a);
	}

	&.c-dual-cta__item--dark {
		box-shadow: none;
		background: var(--secondary-a);

		.c-dual-cta__heading,
		.c-dual-cta__text {
			color: var(--secondary-b);
		}
	}
}

.c-dual-cta__media {
	display: none;

	@include bp(500px) {
		@include flex-extend;
		flex-shrink: 0;
		width: 240px;
	}
}

.c-dual-cta__body {
	@include flex-extend;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: $lsu;
	text-align: center;

	*:last-child {
		margin-bottom: 0;
	}
}

.c-dual-cta__media + .c-dual-cta__body {

	@include bp(500px) {
		align-items: flex-start;
		text-align: left;
	}
}

.c-dual-cta__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-24;
}

.c-dual-cta__text {
	@include font-base-16;
	display: block;
	margin-bottom: $bsu;
}

.c-dual-cta__heading ~ .c-dual-cta__text {
	@include font-base-16;
}

.c-dual-cta__link {
	display: block;

	&.t-action--primary {
		background-color: var(--secondary-a);
		color: var(--secondary-b);

		&:hover,
		&:focus {
			background-color: var(--primary-a);
			color: var(--secondary-b);
		}

		.c-dual-cta__item--dark & {
			background-color: var(--primary-a);
			color: var(--secondary-b);

			&:hover,
			&:focus {
				background-color: var(--secondary-b);
				color: var(--secondary-a);
			}
		}
	}
}

@mixin dual-cta-large {

	.c-dual-cta__layout {
		@include flex-extend;
		align-items: stretch;
	}

	.c-dual-cta__item {
		width: 50%;
		&:nth-child(1):nth-last-child(1) {
			// Only child
			width: 100%;
		}

		+ .c-dual-cta__item {
			margin-top: 0;
		}
	}
}

@mixin dual-cta-small {
	.c-dual-cta__layout {
		display: block;
	}

	.c-dual-cta__item {
		width: 100%;

		+ .c-dual-cta__item {
			margin-top: $lsu;
		}
	}
}


// Uses different breakpoints on pages with a sidebar
@include dual-cta-small;

@include bp("large") {
	@include dual-cta-large;
}

.l-page--has-sidebar .l-page__main-controls {
	@include bp("large") {
		@include dual-cta-small;
	}

	@include bp($layout-wrapper-max-width) {
		@include dual-cta-small;
	}
}
