﻿// COMPONENT.SHARE

$share-icon-size: 46px;
$share-icon-font-size: 20px;

$share: #51ae5e;
$facebook: #3b5997;
$twitter: #55acef;
$pinterest: #bd2126;
$google: #d34836;
$weibo: #fa7d3c;


.c-share--floating {
	transition: left 0.3s ease-in;
	position: fixed;
	left: -#{$share-icon-size};
	top: 200px;
	z-index: $z-share;
	width: $share-icon-size;
	padding: 0;
	margin: 0;

	&.is-visible {
		left: 0;
	}
}

.c-share__heading {
	@include font-base-18;
}

.c-share__icon {

	&.t-action--outline-sec {
		color: var(--primary-a);
		box-shadow: inset 0 0 1px var(--primary-a);

		&:hover,
		&:focus {
			background-color: var(--primary-a);
			box-shadow: inset 0 0 1px var(--primary-a);
		}
	}
}
