﻿// COMPONENT.SEARCH-RESULT


.c-search-result__heading {
	@include font-heading-24b;
	display: block;
	margin-bottom: $ssu;
	color: $body-color;

	&.t-link {
		&:focus,
		&:hover {
			color: $body-color;
			text-decoration-color: $primary-a;
			text-decoration-color: var(--primary-a);
		}
	}

	.c-search-result.t-hover-parent:hover &,
	.c-search-result.t-hover-parent:focus & {
		color: $body-color;
		text-decoration-color: $primary-a;
		text-decoration-color: var(--primary-a);
	}
}
