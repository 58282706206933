﻿// CONTROL.CTA

.c-cta__heading {
	@include font-heading-20;
	color: var(--secondary-a);
	margin-bottom: 0.5em;

	.t-bg__text--sec & {
		color: var(--secondary-b);
	}

	@include bp("large") {
		@include font-heading-24;
	}
}

.c-cta__text {
	@include font-body-text;
	color: var(--secondary-a);
	margin-bottom: 2em;

	.t-bg__text--sec & {
		color: var(--secondary-b);
	}
}

.c-cta__link {
	@include button("medium");

	@include bp("medium") {
		@include button-size("large");
		margin-left: $bsu;
		white-space: nowrap;
	}

	border: 1px solid transparent;

	&.t-action--primary {
		background-color: var(--secondary-a);

		.t-bg__text--sec & {
			background-color: var(--primary-a);
		}

		&:hover {
			background-color: var(--primary-a);

			.t-bg__text--sec & {
				background-color: var(--secondary-b);
				color: var(--secondary-a);
				border-color: var(--secondary-a);
			}
		}
	}
}