

.l-page__hero-inline {
	margin-top: $bsu;
	margin-bottom: $bsu;

	@include bp($layout-page-bp) {
		margin-top: ($lsu + $bsu);
		margin-bottom: ($lsu + $bsu);

		.c-content-type-tghhomepage & {
			margin-top: $hsu;
		}
	}
}


.l-page__main-controls {
	&:last-child {
		margin-bottom: $lsu;
		.c-content-type-tghhomepage & {
			margin-bottom: 0;
		}
	}
}