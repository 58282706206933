﻿// CONTROL.JOB-ADDER

.c-job-adder {
    padding: $lsu 0;
    background-color: $green--grey;

    @include bp("large"){
        padding: $hsu 0;
    }
}

.c-job-adder__heading {
    color: var(--secondary-a);
}

.c-job-adder__content {
}

.ja-job-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $bsu;

    @include bp("medium") {
        grid-template-columns: repeat(2, 1fr);
    }

    @include bp("large") {
        grid-template-columns: repeat(3, 1fr);
    }
}

#ja-jobs-widget .job {
    padding: $bsu;
    background-color: var(--secondary-a);
    color: var(--secondary-b);

    @include bp("large") {
        padding: $lsu;
    }
}

#ja-jobs-widget {
    .ja-job-list .title {
        color: var(--secondary-b);
        margin-bottom: $bsu;

        a {
            @include font-base-24sb;
            color: var(--secondary-b);
        }
    }

    .ja-job-details .title {
        @include font-base-28sb;
        color: $body-color;
        margin-bottom: $bsu;

        @include bp("large") {
            @include font-base-36sb;
        }
    }
}


#ja-jobs-widget .classifications {
    padding: 0;
    list-style: none;
    margin-bottom: $bsu;

    li {
        @include font-heading-18;
    }
}

#ja-jobs-widget .date-posted {
    @include font-base-14;
}

.ja-job-list .summary {
    @include font-base-16;
    margin-bottom: $lsu;
}

#ja-jobs-widget .description {
    @include body-text;
}

.ja-job-list .view-details {
    @include button("medium");
    color: var(--primary-b);
    background-color: var(--primary-a);

    &:hover,
    &:focus {
        color: var(--secondary-a);
        background-color: var(--secondary-b);
    }
}

#ja-jobs-widget .ja-job-details {
    padding: $lsu;
    background-color: var(--primary-b);
}

.ja-job-details .apply {
    display: inline-flex;
    flex-direction: column-reverse;
    gap:  $lsu;
    margin-top: $bsu;

    .back-link {
        color: var(--primary-a);
    }

}

#ja-jobs-widget .ja-button {
    @include button("medium");
    color: var(--primary-b);
    background-color: var(--primary-a);

    &:hover,
    &:focus {
        color: var(--secondary-b);
        background-color: var(--secondary-a);
    }
}

