﻿// CONTROL.FAQ

.c-faq {
	@include control;
}

.c-faq__layout {
	@include layout-wrapper;
}

.c-faq__list {
	@include list-reset;
	@include list-stacked;
}

.c-faq__item {
	@include ui-border('all');
	border-radius: 4px;
	margin-bottom: $tsu;
	box-shadow: none;
	transition-property: box-shadow, border;
	transition-duration: 0.25s;
	transition-timing-function: ease-in-out;

	&[aria-expanded="false"] {
		&:hover,
		&:focus {
			border-color: $green--grey;
			background-color: $green--grey;
			box-shadow: 0 2px 4px 0 rgba($black, 0.1);
		}
	}

	&.t-border-hover {
		&[aria-expanded="false"] {
			&:hover,
			&:focus {
				border-color: $green--grey;
			}
		}
	}
}

.c-faq__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-right: $bsu * 3;
	cursor: pointer;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-faq__item-heading {
	@include font-heading-16;
	@include bp("large") {
		@include font-heading-20;
	}
}