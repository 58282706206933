﻿// CONTROL.VIDEO

@use "sass:math";

.c-video__embed {
	padding-bottom: percentage(calc(9/16));
}

.c-video__cover {
	@include abs-center;
	width: 100%;
}

.c-video__icon {
	@include abs-center;
	@include circle(80px);
	background-color: $brand;
	@include link-transition;
	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		@include pseudo-element;
		@include font-icon;
		content: $iconf-play;
		left: inherit;
		top: inherit;
		border: none;
		font-size: 50px;
		position: static;
	}

	@include bp('small') {
		@include circle(90px);

		&::before {
			left: inherit;
			top: inherit;
			border: none;
		}
	}

	@include bp('medium') {
		@include circle(120px);

		&::before {
			left: inherit;
			top: inherit;
			border: none;
		}
	}
}
