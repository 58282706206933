﻿// CONTROL.IMAGE-FEATURE

@mixin image-feature--wide {
	.c-image-feature__layout {
		@include flex-extend;
		padding: 0;
	}

	.c-image-feature__layout--right {
		flex-flow: row-reverse;
	}

	.c-image-feature__figure {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
	}

	.c-image-feature__image {
		height: 100%;
	}

	.c-image-feature__body {
		align-self: unset;
		padding: ($bsu * 3) ($bsu * 2);
	}
}

.l-page:not(.l-page--has-sidebar) {
	@include bp("medium") {
		@include image-feature--wide;
	}
}
.l-page--has-sidebar {
	@include bp("medium") {
		@include bp-below("large") {
			@include image-feature--wide;
		}
	}
	@include bp("extra-large") {
		@include image-feature--wide;
	}
}

.c-image-feature__layout {
	background-color: $green--grey;

	$sectionTheme: ('default','commercial', 'innovation-hub', 'retail', 'residential', 'green-spaces', 'inland-port', 'logistics');
	$sectionThemeColors: ($default, $commercial, $innovation-hub, $retail, $residential, $green-spaces, $inland-port, $logistics);
	$base-color: zip($sectionTheme, $sectionThemeColors);

	@each $c in $base-color {

		@if( nth($c,1) != 'default') {
			&.section-theme-#{nth($c,1)} {
				background-color: nth($c,2) !important;
			}
		}
	}
}

.c-image-feature__layout--right {}

.c-image-feature__figure {}

.c-image-feature__image {}

.c-image-feature__body {
	padding: $bsu;
	text-align: left;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	@include bp("large") {
		padding: $bsu * 4;

		.l-page--has-sidebar & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__heading {
	@include font-heading-24;
	position: relative;
	margin-bottom: $bsu;
	padding-bottom: 0;
	color: $brand;

	&::before {
		display: none;
	}
}

.c-image-feature__text {
	margin-bottom: $bsu;

	@include bp("large") {
		margin-bottom: $bsu * 1.5;
	}
}

.c-image-feature__link {
	@include button("medium");

	@include bp("large") {
		@include button-size("medium");
	}

	.o-theme-dark & {
		&.t-action--primary {
			&:hover,
			&:focus {
				background-color: var(--secondary-b);
				color: var(--secondary-a);
			}
		}
	}
}
