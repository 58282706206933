// CONTROL.EXPLORE-PODS

@use "sass:math";



.c-explore-pods__top {

	.c-explore-pods__heading {

		@include bp("large") {
			padding-left: 0;
		}

		@include font-base-24sb;

		@include bp("medium") {
			@include font-base-28sb;
		}

		@include bp("large") {
			@include font-base-28sb;
		}

		&.t-text {
			color: $body-color;
		}
	}

	.c-explore-pods__heading-mi {
		@include font-heading-13;
		font-style: normal;
	}
}


.c-explore-pods__introduction-link {

	@include font-heading-14;
	@include bp("large") {
		@include font-heading-16;
	}

	@include link-fancy(before);

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		margin-left: $ssu;
	}
}


// Pod
.c-explore-pods__pod {
	
	&::before,
	&::after {
		display: none !important;
	}
}


.c-explore-pods__pod-shadow {
	content: "";
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 150px;

	background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 100%);
	//background: linear-gradient(to top, var(--secondary-a) 0%, transparent 100%);
}

.c-explore-pods__pod-image-fallback {
	background: $grey--dark;
	height: 100%;
}


.c-explore-pods__pod-title {
	display: block;
	padding-right: calc(1em + #{$tsu});
	margin-top: $bsu;

	@include font-heading-18;
	@include bp("large") {
		@include font-heading-22;
	}
}

.c-explore-pods__pod-title-text {
	@include link-arrow;
}