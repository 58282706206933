﻿// CONTROL.STATISTICS

.c-statistics-panel {
	padding: $lsu 0;

	@include bp("large") {
		padding: ($bsu * 5) 0;
	}

	.l-page--has-sidebar & {
		padding: ($bsu * 5) $bsu;
	}

	> .o-layout-wrapper {
		padding: 0 $bsu;
	}

	+ .c-statistics-panel {
		@include bp-below("large") {
			margin-top: -#{$lsu};
			padding-top: 0;
		}

		@include bp("large") {
			margin-top: -#{$hsu};
			padding-top: 0;
		}
	}
}

.c-statistics-panel__top-layout {
	margin-bottom: $lsu;

	@include bp("medium") {
		grid-gap: ($bsu * 3);
		margin-bottom: $hsu;
	}

	@include bp("large") {
		grid-gap: ($bsu * 4);
	}

	@include bp("extra-large") {
		grid-gap: ($bsu * 5);
	}
}

.c-statistics-panel__bottom-layout {
	@include bp("medium") {
		grid-gap: ($bsu * 3);
	}

	@include bp("large") {
		grid-gap: ($bsu * 4);
	}

	@include bp("extra-large") {
		grid-gap: ($bsu * 5);
	}

	
}


.c-statistics-panel__heading {
	@include font-base-32sb;

	@include bp("medium") {
		@include font-base-45sb
	}

	@include bp("large") {
		@include font-base-60sb;
	}

	color: var(--primary-b);

	strong {
		color: $green--pale;
		display: block;
	}
}

.c-statistics-panel__introduction-text {
	@include font-heading-18b;
	
	@include bp("medium") {
		@include font-heading-20;
	}
}



.c-statistics-panel__pods .c-pod__head {
	&::after {
		border-color: transparent var(--secondary-a) transparent transparent;
	}
}

.c-statistics-panel__pods-list {
	margin-bottom: $bsu;

	@include bp("medium") {
		grid-gap: $lsu;
		margin-bottom: $lsu;

		&.c-statistics-panel__pods-list-bottom {
			grid-template-columns: repeat(1,1fr);
		}
	}

	@include bp("large") {

		&.c-statistics-panel__pods-list-bottom {
			grid-template-columns: repeat(2,1fr);
		}
	}
}

.c-statistics-panel__detail-property {
	margin-bottom: $bsu;
}

.c-statistics-panel__detail-property-key {
	@include font-heading-18;
}

.c-statistics-panel__detail-property-value {
	@include font-base-32sb;

	@include bp("medium") {
		@include font-base-45sb;
	}

	@include bp("large") {
		@include font-base-60sb;
	}
}

.c-statistics {
	display: flex;
	justify-content: space-around;
	background-color: $brand;
}

.c-statistics-item {
	padding: $bsu $lsu;
	text-align: center;
	color: $white;
}
