// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: var(--secondary-a);

$navigation-bp: $header-bp;
$navigation-bp--large: $header-bp--large;

.c-navigation {
	@include bp-below($navigation-bp) {
		display: none;
		width: 100%;
		background-color: $green--darkest;

		&[aria-expanded="true"] {
			display: block;
			position: absolute;
			top: $header-height;
			top: var(--header-height);
			left: 0;
			height: calc(100vh - #{$header-height});
			height: calc(100vh - var(--header-height));
			overflow: auto;
		}
	}

	@include bp($navigation-bp) {
		align-self: stretch;
		display: flex;
		align-items: stretch;
		order: 4;
		flex: auto;
		flex-direction: row;
		padding-left: 15%;
	}

	@include bp("extra-large") {
		order: 1;
		flex-direction: row;
		justify-content: center;
		padding-left: 0;
	}
}

.c-navigation__items {

	@include bp($navigation-bp) {
		border-top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		padding: 0;
	}
}

.c-navigation__item {
	border-bottom: 1px solid $carcoal--dark;

	@include bp($navigation-bp) {
		position: relative;
		border: 0;
		text-align: left;
		vertical-align: bottom;
		display: block;
		align-items: stretch;
	}

	&.c-navigation__item--megamenu {
		@include bp($navigation-bp) {
			position: static;
		}
	}

	@include bp-below($navigation-bp) {
		&[aria-current="true"] {
			.c-navigation__link {
				position: relative;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 2px;
					background: var(--primary-a);
				}
			}
		}
	}
}

.c-navigation__item--home {
	border-top: 1px solid $carcoal--dark;

	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__item--secondary {
	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__item--btn {
	padding: $bsu;

	@include bp($navigation-bp) {
		display: none;
		padding: 0;
	}
}

.c-navigation__link {
	@include font-heading-14;
	display: block;
	padding: $ssu $hsu $ssu $bsu;
	position: relative;

	@include bp($navigation-bp) {
		display: block;
		align-items: center;
		// Unequal top and bottom padding to align with logo
		padding: ($ssu + 5px) ($bsu - $tsu) $ssu;

		@include bp("extra-large") {
			padding: ($ssu + 10px) $bsu $ssu;
		}

		white-space: nowrap;

		@include link-fancy;

		&::after {
			left: $tsu;
			bottom: 0;
			display: block;
		}

		&:hover,
		&:focus-visible,
		.c-navigation__item[aria-current="true"] &,
		.c-navigation__item[aria-expanded="true"] & {
			&::after {
				width: calc(100% - #{$bsu * 2});
				opacity: 1;
				left: $bsu;
			}

			&.t-link--primary-hover:hover,
			&.t-link--primary-hover:focus {
				color: $green--pale;
			}
		}
	}

	&.t-link--primary-hover:hover,
	&.t-link--primary-hover:focus {
		color: $green--pale;
	}

	@include bp("extra-large") {
		&::after {
			bottom: 0;
		}
	}
}

.c-navigation__link-text_mi {
	@include font-base-18;
	display: block;
	font-style: normal;

	.c-navigation__link:hover &,
	.c-navigation__link:focus & {
		color: $green--pale;
	}

	@include bp("large") {
		@include font-base-22;
	}
}

.c-navigation__link-text {
	display: inline-block;
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp($navigation-bp) {
		line-height: 1;
	}
}

.c-navigation__expander {
	@include bp-below($navigation-bp) {
		position: absolute;
		right: $bsu * 1.5;
		top: 50%;
		transform: translateY(-50%);
		font-size: 25px;
	}
	@include bp($navigation-bp) {
		margin-left: $tsu;
		display: none;
	}

	&::before {
		display: block;

		@include font-icon;

		@include bp-below($navigation-bp) {
			content: $iconf-arrow-right;

			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(90deg);
			}
		}
		@include bp($navigation-bp) {
			content: $iconf-arrow-down-chevron;

			font-size: 0.7em;
			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(-180deg);
			}
		}

		@include link-transition(transform);
		transform-origin: 50% 50%;
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp($navigation-bp) {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($navigation-bp) {
		@include ui-border("bottom");
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-base-14;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp($navigation-bp) {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__footer {
	background: $green--darkest;
	padding: $lsu $bsu;

	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__social-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-navigation__social-item {
	padding-left: $bsu;
	padding-top: $bsu;
}

.c-navigation__social-link {
	@include circle(40px);
	@include link-transition;
	font-size: 20px;
	border: 1px solid currentColor;
}

// Global nav
.c-navigation__global-nav {
	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__global-nav-list {
	@include list-reset;
	margin-top: $ssu;
}

.c-navigation__global-nav-item {}

.c-navigation__global-nav-link {
	@include font-base-12;
	display: block;
	padding: $ssu $bsu;

	&::after {
		@include font-icon;
		content: $iconf-external;
		display: inline-block;
		margin-left: $ssu;
		vertical-align: middle;
	}

	&[href^="http:"],
	&[href^="https:"] {
		&::after {
			content: $iconf-external;
		}
	}
}
